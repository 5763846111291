@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
--green: #C3DF68;
--orange: #FF934F;
--blue: #78E0DC;
--grey: #555358;
}
*{
 border-style: border-box;
 font-family: Inter
}

html, body, #root {
    width: 100%;
    height: 100%;
}